<script>
import { Bar } from 'vue-chartjs';
import moment from 'moment';

export default {
  extends: Bar,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    randomColorGenerator() {
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
    },
    makeChart() {
      const labels = [];
      const dataset = [];

      if (this.items && this.items.length > 0) {
        this.items.forEach(function (item) {
          labels.push(moment(new Date(item.date)).format('DD/MM/YYYY'));
          dataset.push(item.count);
        });
      }

      this.renderChart(
        {
          labels: labels,
          datasets: [
            {
              label: 'Cadastrados',
              data: dataset,
              hover: true,
              backgroundColor: '#384b60',
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  suggestedMin: 0,
                  precision: 0,
                  stepSize: 1,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.makeChart();
  },
};
</script>
